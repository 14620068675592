fieldset {
	border: 0;
}

.ui-form-legend {
	background: #777777;
	font-size: 1.0625rem;
	height: 18px;
	padding: 6px 8px;
	text-transform: uppercase;
}

.ui-form-legend:hover {
	cursor: pointer;
}

.ui-form-legend span.icon {
	background: url("/core/public/shared/assets/images/uitags/uitags.png") no-repeat scroll 100% 50% transparent;
	display: block;
	float: right;
	height: 18px;
	width: 15px;
}

.collapsed .ui-form-legend {
	background: #8a8a8a;
}

.collapsed .ui-form-legend h3 .ui-form-legend-step {
	color: #8a8a8a;
}

.collapsed .ui-form-legend span.icon {
	background-position: 0 50%;
}


.ui-form-legend h3 {
	color: #ffffff;
	font-weight: bold;
	line-height: 18px;
	margin: 0px;
}

.ui-form-legend h3 .ui-form-legend-step {
	background: #ffffff;
	border: 0;
	border-radius: 50%;
	color: #757575;
	float: left;
	height: 18px;
	margin-right: 8px;
	text-align: center;
	width: 18px;
}

.ui-form-panel,
.ui-data-shipping,
.ui-data-payment,
.ui-data-subtotal-zero {
	padding: 16px !important;
}

.ui-form-panel {
	border: 2px solid #777777;
}

.ui-data-shipping,
.ui-data-payment {
	border-bottom: 1px solid #ebebeb;
}

.balance-zero-show .ui-data-payment {
	border-bottom: 0;
	padding: 0 !important;
}

.collapsed .ui-form-panel {
	display: none;
}

.ui-form-buttons.ui-form-field-shipping,
.ui-form-buttons.ui-form-field-payment {
	padding-bottom: 0 !important;
}

.ui-form-buttons.ui-form-field-shipping.edit-credit-card-view {
	text-align: left !important;
}

.ui-form-fields {
	padding: 0 0 0 240px;
}

.ui-form-field {
	display: inline;
	float: left;
	line-height: 30px;
	padding: 6px 0;
	position: relative;
}

.ui-form-field #paymentSuccessMessage {
	text-align: left;
}

#ClearCartLink {
	line-height: 30px;
	margin-left: 10px;
}

.ui-form-field.inline {
	margin: 0 0 0 6px;
}

.ui-form-label {
	height: 18px;
	left: -240px;
	line-height: 18px;
	padding: 6px 6px 6px 0;
	position: absolute;
	text-align: right;
	top: 6px;
	width: 234px;
}

.ui-form-field-subtitle .ui-form-label label,
.ui-form-field-subtitle .ui-form-label span {
	color: #000000;
	font-family: inherit;
}

.ui-form-input {
	line-height: 30px;
	min-height: 30px;
	overflow: hidden;
}

.ui-form-text input {
	display: inline;
	float: left;
}

.clear:after {
	clear: both;
}

.clear:before,
.clear:after {
	content: "";
	display: table;
}

.ui-form-note-extra {
	color: #777777;
	font-size: 0.6875rem;
	font-style: italic;
}

input {
	padding: 6px;
}

select {
	padding: 5px;
	line-height: 28px;
}

select,
input {
	vertical-align: middle;
}

input[type=radio] {
	border: none;
	margin: 0;
}

input[type=image] {
	border: none;
	padding: 0;
	vertical-align: top;
}

.ui-form-select select {
	display: inline;
	float: left;
	padding: 6px;
}

.ui-data-button {
	bottom: 10px !important;
	position: absolute !important;
	right: 16px !important;
}

.form-shipping-info .ui-form-buttons,
.form-payment-info .ui-form-buttons {
	border: 0;
	padding: 0 0 0 240px !important;
}

.ui-form-buttons {
	margin: 0;
	overflow: hidden;
	padding: 12px 0 0 240px;
}

.ui-form-input.ui-form-button {
	line-height: normal;
}

.ui-form-text-phone-extension {
	width: auto;
	display: inline-block;
	margin-left: 10px;
}

.ui-form-text-phone-extension .ui-form-text-phone-extension-field {
	float: none;
	width: 120px;
}

.ui-data-shipping-address a {
	word-break: break-all;
}

.recurring-billing-period-field.recurring-billing-period-field {
	clear: left;
	padding: 15px;
}

.recurring-billing-period-field .ui-form-text-overlay {
	position: absolute;
	top: 12px;
	left: 5px;
}

.recurring-billing-period-field .recurring-billing-every {
	margin-right: 5px;
	width: fit-content;
}

.recurring-billing-period-field .recurring-billing-every .ui-form-input {
	padding-top: 5px;
}

.recurring-billing-period-field .recurring-billing-every-number {
	margin-right: 5px;
	z-index: 1;
	width: fit-content;
}

.recurring-billing-period-field .recurring-billing-unit-id {
	margin-right: 5px;
	width: fit-content;
}

.recurring-billing-period-field .recurring-billing-every-error {
	clear: left;
	margin-top: -32px;
	z-index: 0;
}

.recurring-billing-period-field .recurring-billing-every {
	margin-right: 5px;
	width: 90px;
}

#RecurringBillingPeriodFields.recurring-billing-fields {
	border: solid 1px #ccc;
}

#RecurringBillingPeriodFields label {
	padding-top: 5px;
}

#RecurringBillingPeriod,
#RecurringBillingStartDate,
#RecurringBillingFee {
	max-width: 272px;
	width: 100%;
}

.recurring-billing-period-field .ui-form-note {
	width: 100%;
	clear: both;
}

@media (max-width: 767px) {
	#RecurringBillingPeriod,
	#RecurringBillingStartDate,
	#RecurringBillingFee,
	.recurring-billing-period-field .recurring-billing-every {
		max-width: 100%;
		width: 100%;
		margin-left: 0px;
		margin-right: 0px;
	}

	.recurring-billing-fields.ui-form-fields {
		padding: 0px;
	}

	.recurring-billing-period-field .ui-form-label {
		height: auto;
		left: 0;
		position: static;
		text-align: left;
		top: 0;
		width: 100%;
	}

	.recurring-billing-period-field .ui-form-text-overlay {
		top: 45px;
	}

	.recurring-billing-period-field .recurring-billing-unit-id {
		margin-right: 0;
		width: 50%;
	}

	.recurring-billing-period-field .recurring-billing-every-number {
		margin-right: 1%;
		width: 49%;
	}
}

